<template>
  <div>
    <ProfileTitle
      v-if="profile"
      :profile="profile"
      :title="$t('Pages.Profile.skills_tools')"
      array="skills"
      button-id="skill"
      :help="true"
      @button-event="addOtherSkill"
      @icon-event="openSkillDialog"
    />
    <p
      v-if="profile.skills && profile.skills.length"
      class="pl-0"
      v-text="$t('Pages.Profile.highlight')"
    />
    <transition-group
      id="skill-container"
      name="slide-y-transition"
      tag="v-layout"
      class="row wrap resume-cards"
    >
      <v-col
        v-for="(skill, index) in profile.skills"
        :key="'starred-' + skill.skill"
        cols="12"
        sm="4"
      >
        <ProfileCard
          v-if="!skill.isEditing"
          :title="skill.skill"
          :fill-height="true"
          class="fab-30-px"
          :menu-id="'more-skill-' + index"
          :list="[{
                    id: 'edit-skill-' + index,
                    clickEvent: 'edit-item',
                    text: $t('Common.edit')
                  },
                  {
                    id: 'delete-skill-' + index,
                    clickEvent: 'delete-item',
                    text: $t('Common.delete')
                  },
          ]"
          @edit-item="editSkill(skill, index)"
          @delete-item="itemToDelete = skill; deleteSkillDialog = true"
        >
          <span slot="star">
            <v-btn
              v-if="skill.star"
              :id="'starred-' + index"
              class="elevation-1 avatar-chip fab-scaling-icon-hover fav-star"
              color="success"
              fab
              absolute
              small
              @click="skill.star = !skill.star; $emit('save-content', skill, 'skills/star')"
            >
              <v-icon class="star-hover cursor-pointer">
                star
              </v-icon>
            </v-btn>
            <v-btn
              v-else
              :disabled="hasFiveStars"
              color="success"
              fab
              absolute
              small
              class="elevation-1 avatar-chip fab-scaling-icon-hover fav-star"
              @click="skill.star = !skill.star; $emit('save-content', skill, 'skills/star')"
            >
              <v-icon class="star">
                star_border
              </v-icon>
            </v-btn>
          </span>
          <div slot="tooltip">
            <div>
              <span
                v-if="skill.monthsOfExperience !== null && skill.monthsOfExperience !== undefined"
              >
                <v-tooltip
                  color="light-blue darken-4"
                  right
                >
                  <template #activator="{ on }">
                    <span
                      class="skill-time-value"
                      v-on="on"
                    >
                      <v-icon class="mr-2">
                        schedule
                      </v-icon>
                      <span
                        v-if="skill.monthsOfExperience >= 12"
                        class="mr-1"
                      >
                        {{ formatYear(skill.monthsOfExperience) }}
                      </span>
                      <span>{{ formatRemainingMonths(skill.monthsOfExperience) }}</span>
                    </span>
                  </template>
                  <span>Experience with skill</span>
                </v-tooltip>
              </span>
            </div>
            <div>
              <v-tooltip
                v-if="skill.monthsSinceLastUsed !== null && skill.monthsSinceLastUsed !== undefined"
                color="light-blue darken-4"
                right
              >
                <template #activator="{ on }">
                  <span
                    class="skill-time-value"
                    v-on="on"
                  >
                    <v-icon class="mr-2">
                      update
                    </v-icon>
                    <span
                      v-if="skill.monthsSinceLastUsed >= 12"
                      class="mr-1"
                    >
                      {{ formatYear(skill.monthsSinceLastUsed) }}
                    </span>
                    <span>{{ formatRemainingMonths(skill.monthsSinceLastUsed) }}</span>
                  </span>
                </template>
                <span>Time since last use</span>
              </v-tooltip>
            </div>
            <div
              v-if="skill.monthsOfExperience === null || skill.monthsOfExperience === undefined"
              style="height: 1.56rem;"
            />
          </div>
        </ProfileCard>
        <v-card
          v-else
          class="pa-3 rounded-xl"
          color="neutral"
        >
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              v-model="editedSkill.skill"
              :label="$t('Pages.Profile.skill_or_tool')"
              outlined
              dense
              @keydown="isChanged = true"
            />
            <v-btn
              text
              @click="cancelEdit(skill)"
            >
              {{ $t('Common.cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              @click="saveSkill(skill)"
            >
              {{ $t('Common.save') }}
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </transition-group>
    <transition name="slide-y-transition">
      <EmptySectionText
        v-if="!profile.skills || !profile.skills.length"
        icon="library_books"
        :title="$t('Pages.Profile.work_related')"
        @click-event="openAdditionalSkillDialog"
      />
    </transition>
    <v-dialog
      v-model="deleteSkillDialog"
      max-width="500"
    >
      <CustomDialog
        title-icon="delete"
        :title="$t('Pages.Profile.delete_skill')"
        :second-title="$t('Pages.Profile.delete_skill_text')"
        :action-button="{
          class: 'deleteAction',
          id: 'delete-skill-confirm-btn',
          text: $t('Common.delete')
        }"
        :close-button="{
          id: 'delete-skill-cancel-btn',
          text: $t('Common.cancel')
        }"
        @close-event="deleteSkillDialog = false"
        @perform-action="handleDelete"
      />
    </v-dialog>
    <v-dialog v-model="skillDialog">
      <CustomDialog
        title-icon="add_circle"
        :title="$t('Pages.Profile.non_work')"
        :second-title="$t('Pages.Profile.non_work_text')"
      >
        <div slot="custom">
          <v-form
            ref="autoCompleteSkill"
            style="width: 100%;"
            @submit.prevent=""
          >
            <v-text-field
              id="other-skill-autocomplete"
              v-model="skillSelect"
              outlined
              rounded
              dense
              color="accent"
              :label="$t('Pages.Profile.skill_or_tool')"
            />
          </v-form>
        </div>
        <div
          slot="buttons"
          class="button-container"
        >
          <v-btn
            id="close-skill-btn"
            text
            rounded
            @click="skillDialog = false"
            v-text="$t('Common.cancel')"
          />
          <v-btn
            id="save-save-btn"
            text
            rounded
            class="cta-btn"
            @click="currentSkill.skills.push(skillSelect); onSaveSkill(currentSkill.skills[0]); skillSelect = ''"
            v-text="$t('Common.save')"
          />
        </div>
      </CustomDialog>
    </v-dialog>
    <v-dialog
      v-model="additionalSkillDialog"
      max-width="500"
    >
      <CustomDialog
        title-icon="info"
        :title="$t('Pages.Profile.non_work_related')"
        :second-title="$t('Pages.Profile.non_work_1')"
        :text="$t('Pages.Profile.non_work_2')"
        :close-button="{
          id: 'close-non-related-btn',
          text: $t('Common.close')
        }"
        @close-event="additionalSkillDialog = false"
      />
    </v-dialog>
    <v-dialog
      v-model="editSkillTimeDialog"
      max-width="500"
    >
      <CustomDialog
        :title="currentSkill.skill"
        title-icon="schedule"
        :second-title="$t('Pages.Profile.edit_amount')"
        :action-button="{
          class: 'cta-btn',
          id: 'edit-confirm-btn',
          text: $t('Common.save')
        }"
        :close-button="{
          id: 'edit-cancel-btn',
          text: $t('Common.cancel')
        }"
        @close-event="editSkillTimeDialog = false"
        @perform-action="editWorkedMonths(currentSkill)"
      >
        <div slot="custom">
          <h4
            v-if="currentSkill.deviantMonths !== null"
            class="text-center mt-4"
          >
            {{ formatYear(currentSkill.deviantMonths) }}&nbsp;{{ formatRemainingMonths(currentSkill.deviantMonths) }}
          </h4>
          <div class="text-center mt-5">
            <v-btn
              class="mr-1"
              light
              depressed
              rounded
              :disabled="currentSkill.deviantMonths === 0 || currentSkill.deviantMonths < 6"
              @click="currentSkill.deviantMonths -= 6"
            >
              -6 {{ $t('Pages.Profile.months') }}
            </v-btn>
            <v-btn
              light
              depressed
              rounded
              @click="currentSkill.deviantMonths += 6"
            >
              +6 {{ $t('Pages.Profile.months') }}
            </v-btn>
          </div>
        </div>
      </CustomDialog>
    </v-dialog>
  </div>
</template>

<script>
import ProfileTitle from '@/pages/profile/edit/components/EditProfileTitles.vue'
import ProfileCard from '@/pages/profile/components/ProfileCard.vue'
import EmptySectionText from '@/pages/profile/edit/components/EditProfileEmptySection.vue'
import CustomDialog from '@/common/CustomDialog.vue'
import EventBus from '@/common/EventBus.js'

export default {
  components: {
    ProfileTitle,
    ProfileCard,
    CustomDialog,
    EmptySectionText
  },
  props: {
    profile: {
      type: Object,
      default: () => {
      }
    },
    route: {
      type: String,
      default: 'profile'
    }
  },
  data () {
    return {
      deleteSkillLoader: false,
      skillSelect: null,
      editSkillTimeDialog: false,
      skillDialog: false,
      currentSkill: {},
      additionalSkillDialog: false,
      infoSkillDialog: false,
      deleteSkillDialog: false,
      skillErrors: [],
      showError: false,
      skillList: [],
      suggestions: [],
      itemToDelete: null,
      hasFiveStars: false,
      autoCompleteLoader: false,
      valid: false,
      isChanged: false,
      editedSkill: null
    }
  },
  watch: {
    'currentSkill.skill': function (newVal) {
      if (!newVal) return
      if (this.$refs['skill' + this.currentSkill.index]) {
        this.$refs['skill' + this.currentSkill.index].isMenuActive = false
      }
      if (newVal.length && newVal.replace(/^\s+|\s+$/gm, '').length < 1) {
        this.skillErrors = 'Cannot contain only spaces'
      } else {
        this.skillErrors = []
      }
    }
  },
  created () {
    EventBus.$on('duplicateErrorsskills', (error) => {
      this.skillErrors = error
    })
    EventBus.$on('savedskills', () => {
      this.skillDialog = false
      this.checkHasFiveStars()
    })
    EventBus.$on('savedskills/star', () => {
      this.checkHasFiveStars()
    })
    EventBus.$on('deleteDialogskill', () => {
      this.deleteSkillDialog = false
      this.checkHasFiveStars()
    })
  },
  beforeMount () {
    this.checkHasFiveStars()
  },
  beforeDestroy () {
    EventBus.$off('duplicateErrorsskill')
    EventBus.$off('savedskill')
    EventBus.$off('savedskillstar')
    EventBus.$off('deleteDialogskill')
  },
  methods: {
    handleDelete () {
      this.$emit('delete-item', this.itemToDelete.skill)
    },
    removeSkillFromList (skill, index, list) {
      if (index > -1) {
        this[list].skills.splice(index, 1)
      }
    },
    addSkillsFromList (e, list, currentVModel) {
      if (e === null || e === undefined) return
      if (!this[list].skills.includes(e)) {
        this[list].skills.push(e)
        this[currentVModel] = null
      }
      this.skillList = []
      this.$refs.autoCompleteSkill.reset()
      setTimeout(() => {
        this.$refs.autoCompleteSkill.reset()
        this.skillSelect = null
      }, 100)
    },
    addSkill (type, skill) {
      this[type].skills.push(skill)
    },
    checkHasFiveStars () {
      this.hasFiveStars = this.profile.skills.filter(skill => skill.star).length >= 5
    },
    openSkillDialog () {
      this.additionalSkillDialog = true
    },
    formatYear (months) {
      const years = Math.floor(months / 12)
      if (years === 1) {
        return years + ' yr'
      } else {
        return years + ' yrs'
      }
    },
    formatRemainingMonths (months) {
      if (months === null || months === undefined) {
        return ''
      } else if (months < 12) {
        if (months === 1 || months === 0) {
          return months + ' mo'
        } else {
          return months + ' mos'
        }
      } else {
        if (months % 12 === 1) {
          return (months % 12) + ' mo'
        } else {
          return (months % 12) + ' mos'
        }
      }
    },
    addOtherSkill () {
      this.skillErrors = []
      this.currentSkill = { skills: [], index: this.profile.skills.length }
      const skillArray = []
      for (const key in this.profile.skills) {
        skillArray.push(this.profile.skills[key].skill)
      }
      this.skillDialog = true
    },
    editSkill(skill, index) {
      this.editedSkill = { ...skill };
      this.$set(this.profile.skills[index], 'isEditing', true);
    },
    cancelEdit(skill) {
      skill.isEditing = false;
      this.editedSkill = null;
    },
    saveSkill(skill) {
      const oldSkill = skill.skill;
      skill.isEditing = false;
      Object.assign(skill, this.editedSkill);
      this.editedSkill = null;
      this.$http.delete(`${this.route}/skills/${encodeURIComponent(oldSkill)}`).then(
        () => {
          this.$emit('save-content', skill, 'skills');
        },
        err => {
          console.error(err);
          this.$root.$emit('errorMessage', err.response);
        }
      );
    },
    editWorkedMonths (skill) {
      const deviantMonths = skill.deviantMonths - skill.monthsOfExperience
      const value = {
        skill: skill.skill,
        deviation: deviantMonths
      }
      this.$http.post(this.route + '/skills/deviation', value).then(
        res => {
          // eslint-disable-next-line vue/no-mutating-props
          this.profile.skills = res.data.skills
          this.editSkillTimeDialog = false
        },
        err => {
          console.error(err)
          this.$root.$emit('errorMessage', err.response)
        }
      )
    },
    openAdditionalSkillDialog () {
      this.additionalSkillDialog = true
    },
    onSaveSkill (skill) {
      const payload = {
        skill: skill
      }
      this.$emit('save-content', payload, 'skills')
    }
  }
}
</script>